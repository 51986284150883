@import "../../../styles/settings.scss";

footer {
  margin-top: 50px;
  background: #10111b;
  // height: 100%;
  // margin-top: 115px;
  @include xs {
    width: 100vw;
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
  a {
    text-decoration: none;
    color: #eee;
    font-size: 12px;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
    margin: 0 auto;
    @include xs {
      width: 100vw;
    }
    @include sm {
      width: 100vw;
    }
    @include md {
      width: 100vw;
    }
    .item {
      display: flex;
      flex-wrap: wrap;
      padding: 15px 0;
      width: 100%;

      ul {
        display: flex;
        width: 33.33%;
        justify-content: center;
        @include xs {
          width: 100%;
          margin-top: 10px;
        }
        @include sm {
          width: 100%;
          margin-top: 10px;
        }
        @include md {
          width: 100%;
          margin-top: 10px;
        }
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
          color: $primary-text-color;
          font-size: 12px;
          cursor: pointer;
          a {
            &:hover {
              color: $secondary-color;
            }
            @include md {
              font-size: 18px;
            }
          }
          .button1,
          .button2 {
            padding: 10px 15px;
            border-radius: 3px;
            background-color: #292b3b;
          }
        }
      }
      h2 {
        color: #fff;
        font-size: 14px;
        padding-bottom: 0.625rem;
      }
    }
  }
  .legal {
    padding: 10px;
    background-color: #0a0c11;
    .legal_list {
      width: 90vw;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin: 0 auto;

      @include md {
        margin: 0 auto;
      }
      li {
        margin: 0.125rem 0.625rem;
        white-space: nowrap;
      }
    }
  }
}
