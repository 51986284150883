@import "../../../styles/settings.scss";

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #14151e;
  height: 380px;
  flex: 0 0 22%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 0.2s;
  // margin-top: 15px;
  margin: 15px 15px;
  //   &.product_1 {
  //     background-image: url("../../../assets/images/product_1.png");
  //   }
  //   &.product_2 {
  //     background-image: url("../../../assets/images/product_2.png");
  //   }
  //   &.product_3 {
  //     background-image: url("../../../assets/images/product_3.png");
  //   }
  //   &.product_4 {
  //     background-image: url("../../../assets/images/product_4.png");
  //   }
  //   &.product_5 {
  //     background-image: url("../../../assets/images/product_8.png");
  //   }
  //   &.product_6 {
  //     background-image: url("../../../assets/images/product_6.png");
  //   }
  //   &.product_7 {
  //     background-image: url("../../../assets/images/product_7.png");
  //   }
  //   &.product_8 {
  //     background-image: url("../../../assets/images/product_8.png");
  //   }
  //   &.product_9 {
  //     background-image: url("../../../assets/images/product_9.png");
  //   }
  //   &.product_10 {
  //     background-image: url("../../../assets/images/product_10.png");
  //   }
  //   &.product_11 {
  //     background-image: url("../../../assets/images/product_11.png");
  //   }
  //   &.product_12 {
  //     background-image: url("../../../assets/images/product_12.png");
  //   }
  //   &.product_13 {
  //     background-image: url("../../../assets/images/product_13.png");
  //   }
  //   &.product_14 {
  //     background-image: url("../../../assets/images/product_14.png");
  //   }
  //   &.product_15 {
  //     background-image: url("../../../assets/images/product_15.png");
  //   }
  //   &.product_16 {
  //     background-image: url("../../../assets/images/product_16.png");
  //   }
  //   &.product_17 {
  //     background-image: url("../../../assets/images/product_17.png");
  //   }
  //   &.product_18 {
  //     background-image: url("../../../assets/images/product_18.png");
  //   }
  //   &.product_19 {
  //     background-image: url("../../../assets/images/product_19.png");
  //   }
  //   &.product_20 {
  //     background-image: url("../../../assets/images/product_20.png");
  //   }
  //   &.product_21 {
  //     background-image: url("../../../assets/images/product_21.png");
  //   }
  //   &.product_22 {
  //     background-image: url("../../../assets/images/product_22.png");
  //   }
  @include xs {
    flex: 0 0 100%;
    margin: 0 0 25px 0;
  }
  @include sm {
    flex: 0 0 100%;
    margin: 0 0 25px 0;
  }
  @include md {
    flex: 0 0 80%;
    margin: 0 auto;
    margin: 0 0 25px 0;
  }
  &:hover {
    transform: scale(1.1);
    @include xs {
      transform: none;
    }
    @include md {
      transform: none;
    }
  }
  .name {
    padding: 25px;
    text-transform: uppercase;
    letter-spacing: 2px;
    h2 {
      color: rgb(255, 255, 255);
      font-size: 18px;
      text-align: center;
      line-height: 20px;
      font-weight: 600;
    }
    p {
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      color: $secondary-color;
    }
  }

  .game_logo {
    opacity: 1;
    text-align: center;
    position: absolute;
    top: 10px;
    background-color: rgba($color: #0c0d14, $alpha: 0.8);
    width: 100%;
    letter-spacing: 1px;
    text-transform: uppercase;
    img {
      height: 80%;
    }
  }
  .bottom_bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2px 0;
    display: flex;
    background: $primary-color;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
    color: $primary-text-color;
    border-radius: inherit;

    .status_button {
      cursor: pointer;
      transition: transform 0.2s;
      &:hover {
        transform: scale(1.2);
      }
    }

    .details_button {
      cursor: pointer;
      transition: transform 0.2s;
      &:hover {
        transform: scale(1.2);
      }
    }

    .buy_button {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      background: #181a28;
      padding: 12px 11px;
      border-radius: 60px;
      cursor: pointer;
      transition: transform 0.2s;
      font-size: 22px;
      input {
        position: absolute;
        width: 50px;
        height: 50px;
        opacity: 0;
        cursor: pointer;
      }
      &:hover {
        cursor: pointer;
        background: #212437;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.status_button:hover ~ .status {
  visibility: visible;
  animation: fadeIn linear 0.2s;
  -webkit-animation: fadeIn linear 0.2s;
  -moz-animation: fadeIn linear 0.2s;
  -o-animation: fadeIn linear 0.2s;
  -ms-animation: fadeIn linear 0.2s;
}
// .details_button:hover ~ .features {
//   visibility: visible;
//   animation: fadeIn linear 0.2s;
//   -webkit-animation: fadeIn linear 0.2s;
//   -moz-animation: fadeIn linear 0.2s;
//   -o-animation: fadeIn linear 0.2s;
//   -ms-animation: fadeIn linear 0.2s;
// }
.status,
.features {
  background-color: #0f0f16;
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 100%;
  height: 330px;
  visibility: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  color: #00bea3;
  .row_text {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    p {
      font-size: 16px;
      margin-left: 5px;
      text-transform: capitalize;
      @include md {
        font-size: 18px;
      }
    }
  }
}
.features .row_text {
  display: inline-block;

  p {
    padding: 12px;
    display: inline-block;
    font-size: 11px;
    @include md {
      font-size: 18px;
    }
  }
}
.buy_options {
  visibility: hidden;
  position: absolute;
  top: -45px;
  display: flex;
  justify-content: space-around;
  z-index: 99;
  width: 100%;
  .option {
    border-radius: 50px;
    background-color: #14151e;
    margin-left: 5px;
    // width: 120px;
    text-align: center;
    padding: 5px;
    @include md {
      width: 190px;
      p {
        font-size: 16px;
      }
    }
    &:hover {
      background-color: $secondary-color;
    }
  }
}
input:checked ~ .buy_options {
  visibility: visible;
  animation: fadeIn linear 0.3s;
  -webkit-animation: fadeIn linear 0.3s;
  -moz-animation: fadeIn linear 0.3s;
  -o-animation: fadeIn linear 0.3s;
  -ms-animation: fadeIn linear 0.3s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
