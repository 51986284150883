@import "../../../styles/global.scss";
@import "../../../styles/settings.scss";

.root {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  position: relative;
  @include xs {
    width: 100vw;
  }
  @include sm {
    width: 100vw;
  }
  @include md {
    width: 100vw;
  }
  .image {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    z-index: 5;

    @include xs {
      width: 100vw;
      height: 60%;
      z-index: 1;
      opacity: 0.3;
    }
    @include sm {
      z-index: 1;
      opacity: 0.3;
    }
    @include md {
      height: 103%;
      z-index: 1;
      position: absolute;
      top: 0;
      opacity: 0.6;
    }
    img {
      max-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .text {
    color: $primary-text-color;
    width: 60%;
    margin-top: 80px;
    position: relative;
    z-index: 2;
    background: transparent;
    @include xs {
      width: 95%;
      margin: 0 auto;
      padding-top: 20px;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    @include sm {
      margin-top: 0;
      padding-top: 0;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
    }
    @include md {
      width: 90%;
      margin: 40px auto;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    h3 {
      font-size: 15px;
      background: transparent;
      margin-top: 15px;
      text-transform: uppercase;
      font-weight: 500;
      @include xs {
        margin-top: 0;
      }
    }
    p {
      margin-top: 10px;
      background: transparent;
    }
    .box_row {
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;
      background: transparent;
      @include xs {
        justify-content: center;
      }
      @include sm {
        justify-content: center;
      }

      @include md {
        justify-content: center;
      }
      .box {
        position: relative;
        z-index: 2;
        width: 200px;
        height: 100px;
        background: #14151e;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom: 4px solid $secondary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 25px;
        // &:before{
        //   content: '';
        //   display: block;
        //   position: absolute;
        //   top: -6px;
        //   left: -5px;
        //   right: -8px;
        //   height: 23px;
        //   background: url("../../../assets/images/snow1.png") no-repeat 0 0,
        //   url("../../../assets/images/snow2.png") no-repeat 50% 0,
        //   url("../../../assets/images/snow3.png") no-repeat 100% 0,  
        // }
        @include xs {
          margin-bottom: 10px;
          margin-right: 0;
          width: 80%;
        }
        @include sm {
          width: 80%;
          margin-right: 0;
          margin-bottom: 10px;
        }
        img {
          background: transparent;
        }
      }
    }
    .maintext {
      font-family: $secondary-font;
      font-size: 70px;
      // font-weight: 500;
      margin-bottom: 15px;
      letter-spacing: 3px;
      background: transparent;

      span {
        font-weight: 800;
        font-family: $secondary-font;

        color: $primary-color;
      }
      @include xs {
        font-size: 45px;
      }
      @include sm {
        font-size: 45px;
      }
    }
  }
}
