@import "../../../styles/settings.scss";

.container {
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-top: 25px;
  padding: 10px;
  @include xs {
    margin-top: 10px;
  }
  .box {
    border-bottom: 2px solid $primary-color;
    padding-bottom: 5px;
    border-radius: 1px;
    h1 {
      font-size: 35px;
      line-height: 35px;
      @include xs {
        font-size: 30px;
      }
    }
    h6 {
      line-height: 25px;
      text-align: center;
      @include xs {
        font-size: 13px;
        line-height: 25px;
      }
    }
  }
}
