@import "../../../styles/settings.scss";

.products {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .categories_container {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    background-color: #14151e;
    border-radius: 10px;
    border: 1px solid #272935;
    padding: 25px;
    position: relative;
    z-index: 6;
    flex: 0 0 100%;
    ul {
      display: flex;
      justify-content: space-around;
      @include xs {
        flex-direction: column;
      }
      li {
        list-style: none;
        color: white;
        text-transform: capitalize;
        cursor: pointer;
        &:hover {
          color: $primary-color;
        }
        &.active {
          font-weight: bold;
          color: $primary-color;
        }
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  background-color: #14151e;
  border-radius: 10px;
  border: 1px solid #272935;
  padding: 15px;
  position: relative;
  z-index: 6;
  flex: 0 0 85%;
  h4 {
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    color: white;
    letter-spacing: 2px;
    border-bottom: 1px solid $primary-color;
    padding-bottom: 5px;
  }
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #14151e;
  height: 380px;
  flex: 0 0 22%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 0.2s;

  @include xs {
    flex: 0 0 100%;
    margin-bottom: 25px;
  }
  @include sm {
    flex: 0 0 100%;
    margin-bottom: 25px;
  }
  @include md {
    flex: 0 0 80%;
    margin: 0 auto;
    margin-bottom: 25px;
  }
  &:hover {
    transform: scale(1.1);
    @include xs {
      transform: none;
    }
    @include md {
      transform: none;
    }
  }
  .name {
    padding: 25px;
    text-transform: uppercase;
    letter-spacing: 2px;
    h2 {
      color: rgb(255, 255, 255);
      font-size: 18px;
      text-align: center;
      line-height: 20px;
      font-weight: 600;
    }
    p {
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      color: $secondary-color;
    }
  }
  .game_logo {
    opacity: 1;
    text-align: center;
    position: absolute;
    top: 10px;
    background-color: rgba($color: #0c0d14, $alpha: 0.8);
    width: 100%;
    letter-spacing: 5px;
    text-transform: uppercase;
  }
}
