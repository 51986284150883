@import "../../../styles/settings.scss";

$nav-links: Home, Products, Support, Forum, Join Our Discord, Go To Shop, xxx;

a {
  text-decoration: none;
}

nav {
  background: #0d1014;
  height: 60px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  @include sm {
    display: none;
    border-bottom: none;
    background: none;
    position: relative;
  }
  @include md {
    display: none;
    border-bottom: none;
    background: none;
    position: relative;
  }
  @include xl {
    display: none;
    border-bottom: none;
    background: none;
    position: relative;
  }
  .nav_links {
    display: block;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    &::before {
      background: rgba(0, 0, 0, 0.5);
      content: "";
      height: 100vh;
      left: 0;
      top: 0;
      position: absolute;
      opacity: 0;
      display: none;
      width: 100%;
      transition: all 0.3s;
    }
    li {
      border-bottom: 1px solid rgb(49, 49, 49);
      opacity: 0;
      display: none;
      transform: translateY(-20px);
      transition: all 0.3s;
      a {
        background: #0d1014;
        color: #e0e0e0;
        display: block;
        font-size: 14px;
        font-weight: 500;
        padding: 12px 20px;
        text-align: left;
        width: 100%;
        transition: all 0.3s;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    &:checked + label {
      border: 4px solid silver;
      border-radius: 50%;
      height: 28px;
      top: 16px;
      left: 18px;
      width: 28px;
      transform: rotate(-135deg);
      &::before {
        background: silver;
        top: 8px;
        left: 4px;
        width: 12px;
      }
      &::after {
        background: silver;
        opacity: 1;
        top: 8px;
        left: 4px;
        visibility: visible;
        width: 12px;
      }
      &:hover {
        border-color: $primary-text-color;
        &::before,
        &::after {
          background: $primary-text-color;
        }
      }
    }
    &:checked ~ .nav_links {
      &::before {
        opacity: 1;
        display: block;
      }
      li {
        opacity: 1;
        display: block;
        transform: translateY(0);

        @for $i from 1 through length($nav-links) {
          &:nth-child(#{$i}) {
            transition-delay: ($i - 1)/10 + s;
          }
        }
      }
    }
  }

  label {
    background: none transparent;
    border: 4px solid $primary-text-color;
    border-left: 0 solid transparent;
    border-right: 0 solid transparent;
    cursor: pointer;
    display: block;
    height: 24px;
    position: absolute;
    top: 18px;
    left: 20px;
    width: 24px;
    transition: all 0.2s;
    &::before {
      background: $primary-text-color;
      content: "";
      height: 4px;
      left: 0;
      position: absolute;
      top: 6px;
      width: 24px;
      transition: all 0.2s;
    }
    &::after {
      background: $primary-text-color;
      content: "";
      height: 4px;
      left: 0;
      position: absolute;
      top: 6px;
      opacity: 0;
      visibility: hidden;
      width: 100%;
      transform: rotate(90deg);
      transition: all 0.2s;
    }
  }
}
