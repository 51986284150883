@import "../../../styles/settings.scss";

.title {
  width: 100%;
  text-align: center;
  margin-bottom: 35px;
  color: $secondary-color;
  @include md {
    font-size: 25px;
  }
  h1 {
    color: white;
    font-size: 25px;
    position: relative;
  }

  p {
    font-size: 15px;
    color: $secondary-color;
    line-height: 10px;
    font-family: $secondary-font;
    @include md {
      font-size: 16px;
    }
  }
}

@import "../../../styles/settings.scss";

.container {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  background-color: #14151e;
  border-radius: 10px;
  border: 1px solid #272935;
  padding: 15px;
  position: relative;
  z-index: 6;
  flex: 0 0 85%;

  @include xs {
    padding: 35px;
    width: 90vw;
    margin: 30px auto;
  }
  @include md {
    margin-top: 0;
  }
  .row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 15px;

    .small_box {
      margin: 15px 0;
      flex: 0 0 23%;
      // padding: 5px 80px;
      width: 200px;
      height: 50px;
      border-radius: 2px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;
      text-align: center;
      background-color: #1977bb;
      background-repeat: no-repeat;
      background-size: cover;
      transition: transform 0.2s;
      cursor: pointer;

      .text {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;

        p {
          margin-right: 5px;
          font-weight: 600;
        }
      }
      &:hover {
        transform: scale(1.1);
      }
      @include xs {
        flex: 0 0 100%;
      }
      @include sm {
        flex: 0 0 100%;
      }
      @include md {
        margin: 15px auto;
        text-align: center;
        flex: 0 0 80%;
      }
      .buy_button {
        position: absolute;
        bottom: -18px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #181a28;
        padding: 10px 13px;
        border-radius: 60px;
        cursor: pointer;
        transition: transform 0.2s;
        color: $primary-text-color;
        font-size: 15px;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}
