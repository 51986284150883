@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@100;200;300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo:wght@300;400;500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');$base-size: 12px;

/*** fonts ***/
$primary-font: "Poppins", sans-serif;
$secondary-font: 'Bebas Neue', serif;

/*** base colors ***/
$primary-color: #36abff;
$secondary-color: #ddbb31;

$primary-text-color: #fff;
$secondary-text-color: #d2d2d2;

/*** breakpoints ***/
$screen-xsm-min: 575.99px;
// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

/*** mixins ***/
// Small devices
@mixin xs {
  @media (max-width: #{$screen-xsm-min}) {
    @content;
  }
}
@mixin sm {
  @media (min-width: #{$screen-sm-min}) and (max-width: 765.99px) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) and (max-width: 1199.99px) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}
