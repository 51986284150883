@import "./settings.scss";

html {
  box-sizing: border-box;
  font-size: 100%;
}
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $primary-font;

}

body {
  background-color: #0c0d14;
  background-image: url("../assets/images/deco.png");
  // background-image: url("../assets/images/background_christmas2.png");
  // background-repeat: repeat;
  background-repeat: no-repeat;
  background-position: top right;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  @include xs {
    background-image: none;
  }
  @include sm {
    background-image: none;
  }
  @include md {
    background-image: none;
  }
}

p {
  color: $primary-text-color;
  font-size: 12px;
  @include md {
    font-size: 16px;
  }
}

h6 {
  color: $primary-text-color;
  font-size: 16px;
  font-weight: 400;
  @include xs {
    font-size: 14px;
  }
  @include md {
    font-size: 18px;
  }
  @include lg {
    font-size: 18px;
  }
}

a {
  color: $primary-text-color;
  text-decoration: none;
}
