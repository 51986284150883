@import "../../../styles/settings.scss";



.container {
  display: flex;
  flex-wrap: wrap;
  width: 80vw;
  margin: 0 auto;
  margin-top: 40px;
  font-size: 12px;
  p {
    padding: 0 5px;
  }
  a {
    &.active {
      font-weight: bold;
    }
    text-transform: capitalize;
  }
}


