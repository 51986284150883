@import "../../../styles/settings.scss";
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  width: 80vw;
  margin: 0 auto;
  margin-top: 40px;
  font-size: 12px;
  p {
    padding: 0 5px;
  }
  a {
    &.active {
      font-weight: bold;
    }
    text-transform: capitalize;
  }
  @include xs {
    margin-left: 20px;
    width: 100vw;
  }
  @include sm {
    margin-left: 20px;
    width: 100vw;
  }

  @include md {
    margin-left: 20px;
    width: 100vw;
  }
}
.container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.box {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  background-color: #14151e;
  border-radius: 10px;
  border: 1px solid #272935;
  padding: 15px;
  position: relative;
  z-index: 6;
  flex: 0 0 85%;

  @include xs {
    flex: 0 0 100%;
  }
  @include sm {
    flex: 0 0 100%;
  }

  @include md {
    flex: 0 0 100%;
  }
}

.questions {
  flex-basis: 50%;
}

.category {
  font-size: 12px;
  padding: 0 5px;
  background-color: $primary-color;
  border-radius: 4px;
  margin-right: 10px;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  // text-align: center;
  @include xs {
    min-width: 80px;
    max-width: 80px;
    min-height: 40px;
    max-height: 40px;
  }
  @include sm {
    min-width: 80px;
    max-width: 80px;
    min-height: 40px;
    max-height: 40px;
  }

  @include md {

  }
}
.title {
  cursor: pointer;
  margin: 1px 0;
  border: 1px solid #36384b;
  // background-color: #1c1d29;
  background-color: #23253a;
  display: flex;
  align-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 10px;
  margin-top: 15px;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  transition: all 0.2s ease-in;
  border-radius: 9px;
  min-height: 80px;
  @include xs {
    min-height: 120px;
  }
  @include sm {
    min-height: 120px;
  }

  @include md {
    min-height: 120px;
  }
}

.title:hover h3 {
  color: $primary-color;
  // transition: all 0s ease-in;
}

.title h3 {
  font-size: 14px;
  text-transform: none;
  color: white;
  font-weight: 600;
  transition: all 0.3s ease-in;
}

.title.active h3 {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  // background-color: #ffffff;
  border: none;
  color: $primary-color;
}

.title.active {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: none;
  border: 1px solid #36384b;
}

.arrow {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  font-weight: 700;
  font-size: 2rem;
  width: 25px;
  height: 25px;
}
.content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s linear;
  transform: translate(10px, 0);
}

.content.show {
  padding: 20px;
  // background-color: #23253a;
  background-color: #181927;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  border-bottom: 1px solid #36384b;
  border-right: 1px solid #36384b;
  border-left: 1px solid #36384b;
  transition: all 0.3s linear;
  height: auto;
  max-height: 999px;
  transform: translate(0, 0);
}

p {
  font-size: 14px;
}
