@import "../../../styles/settings.scss";

.container {
  display: flex;
  @include xs {
    display: none;
  }
  @include sm {
    width: 100vw;
  }
  @include md {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }
  .navbar_left {
    display: flex;
    margin-top: 30px;
    flex: 0 0 60%;
    align-items: center;
    @include xs {
      display: none;
    }
    @include sm {
      padding-right: 0;
      justify-content: space-around;
      margin-top: 20px;
    }
    @include md {
      padding-right: 0;
      justify-content: space-around;
      margin-top: 20px;
      flex: 0 0 50%;
    }
    li {
      padding-right: 40px;
      list-style: none;
      background-color: transparent;
      cursor: pointer;
      color: white;
      @include xs {
        padding: 0;
      }
      @include sm {
        padding: 0;
      }
      @include md {
        padding: 0;
      }
      a {
        &:hover {
          color: $secondary-color;
        }
      }
    }
  }
  .navbar_right {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    width: 100%;
    align-items: center;
    @include md {
      justify-content: center;
    }
    li {
      padding-left: 20px;
      list-style: none;
      @include sm {
        padding: 10px;
      }
      @include md {
        padding: 10px;
      }
      .button_blue,
      .button_yellow {
        display: flex;
        .icon {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          padding: 0 10px;
          background-color: darken($primary-color, 10%);
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img {
            background-color: transparent;
            @include sm {
              width: 80%;
            }
            @include md {
              width: 80%;
            }
          }
        }
        .button {
          background: $primary-color;
          border-bottom-right-radius: 4px;
          border-top-right-radius: 4px;
          padding: 10px 20px;
          position: relative;
          &:hover {
            background-color: lighten($primary-color, 10%);
            .icon {
              background-color: lighten($primary-color, 55%);
            }
          }
          // &:before{
          //   content: '';
          //   display: block;
          //   position: absolute;
          //   top: -7px;
          //   left: -51px;
          //   right: -9px;
          //   height: 23px;
          //   background: url("../../../assets/images/snow1.png") no-repeat 0 0,
          //   url("../../../assets/images/snow2.png") no-repeat 50% 0,
          //   url("../../../assets/images/snow3.png") no-repeat 100% 0,  
          // }
          @include sm {
            padding: 5px 10px;
          }
          @include md {
            padding: 5px 10px;
          }
        }
        a {
          font-size: 14px;
          font-weight: 500;
          text-decoration: none;
          color: white;
          background-color: transparent;
          @include sm {
            font-size: 9px;
          }
        }
      }
      .button_yellow {
        .button {
          background-color: $secondary-color;
          &:hover {
            background-color: lighten($secondary-color, 10%);
          }
        }
        .icon {
          background-color: darken($secondary-color, 10%);
        }
      }
    }
  }
}
